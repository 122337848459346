html, body, .iframe {
    height:100%!important;
    padding:0!important;
    margin:0!important;
    border:none!important;
    outline:0!important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 9999;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}